import React, { FC } from "react";

import { Spinner } from "@atlas-ui/components";
import { Document } from "@atlas-ui/types";
import { Row } from "@tanstack/react-table";

import { isEmpty } from "lodash";

interface MyDocumentsTypeCellProps {
  row: Row<Document>;
}

export const MyDocumentsTypeCell: FC<MyDocumentsTypeCellProps> = ({ row }) => {
  if (row.original._isInvalid) {
    return <span data-testid={"invalid-document"} />;
  }

  if (!row.original.type || isEmpty(row.original.type)) {
    return (
      <div className={"flex"}>
        <Spinner size={4} />
      </div>
    );
  }

  const document = row.original;
  return (
    <div className="h-full flex items-start pt-[6px]">
      <span data-testid="document-type">{document.type}</span>
    </div>
  );
};
