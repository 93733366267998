import { useQuery } from "react-query";

import { getDocumentTypesWithCount } from "@atlas-ui/services";
import { DocumentTypeWithCount } from "@atlas-ui/types";

interface UseDocumentTypesWithCountProps<T = DocumentTypeWithCount[]> {
  transform?: (data: DocumentTypeWithCount[]) => T;
  onSuccess?: (data: DocumentTypeWithCount[]) => void;
  enabled?: boolean;
}

export const useDocumentTypesWithCount = <T = DocumentTypeWithCount[]>(
  props?: UseDocumentTypesWithCountProps<T>
): {
  documentTypesWithCount: T;
  isLoadingDocumentTypesWithCount: boolean;
} => {
  const { isLoading, data } = useQuery<DocumentTypeWithCount[]>({
    enabled: !!props?.enabled,
    queryKey: ["document-types-count"],
    queryFn: getDocumentTypesWithCount,
    onSuccess: (data) => {
      props?.onSuccess?.(data);
    },
  });

  const result = props?.transform ? props.transform(data ?? []) : data;

  return {
    documentTypesWithCount: (result ?? []) as T,
    isLoadingDocumentTypesWithCount: isLoading,
  };
};
