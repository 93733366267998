import { FC, useContext } from "react";

import { DocumentTab } from "@atlas-ui/types";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

import { MyDocumentsContext } from "../../lib";

export const MyDocumentsNameHeader: FC = () => {
  const { selectedTab } = useContext(MyDocumentsContext);

  const iconColor =
    selectedTab === DocumentTab.ACTIVE ? "primary" : "destructive";

  const text = selectedTab === DocumentTab.ACTIVE ? "Active" : "Expired";

  return (
    <div className="flex gap-2 min-w-[150px]">
      <DocumentDuplicateIcon
        className={`text-${iconColor} w-[18px] h-[18px]`}
      />
      <span>{text} Documents</span>
    </div>
  );
};
