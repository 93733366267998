import { FC } from "react";

import { Document } from "@atlas-ui/types";
import { Row } from "@tanstack/react-table";

import { cn } from "@/lib/utils";

import dayjs from "dayjs";

export interface MyDocumentsExpiredCellProps {
  row: Row<Document>;
}

export const MyDocumentsExpiredCell: FC<MyDocumentsExpiredCellProps> = ({
  row,
}) => {
  const dayjsDate = dayjs(row.original.expirationDate);
  const isExpired = dayjsDate.isBefore(dayjs());

  return (
    <div
      className="h-full flex items-start pt-[6px]"
      data-testid="expired-cell"
    >
      <span
        className={cn("text-xs", isExpired ? "text-red-500" : "text-gray-500")}
        data-testid={isExpired ? "expired-date" : "valid-date"}
      >
        {dayjsDate.format("MMM DD, YYYY")}
      </span>
    </div>
  );
};
