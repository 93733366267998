import React, { FC, MouseEvent, useState } from "react";

import { Document } from "@atlas-ui/types";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Row } from "@tanstack/react-table";

import { InsightsCreatedAtCell } from "@/app/insights/components/insights-created-at-cell";
import { getDocumentProcessingError } from "@/app/my-documents/lib";
import { DeleteDocumentDialog } from "@/components/common/dialogs/delete-document-dialog";
import { LoadingButton } from "@/components/common/loading-button";
import { cn } from "@/lib/utils";

interface MyDocumentsCreatedAtCellProps {
  row: Row<Document>;
}

export const MyDocumentsCreatedAtCell: FC<MyDocumentsCreatedAtCellProps> = ({
  row,
}) => {
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const document = row.original;
  const error = getDocumentProcessingError(document);

  const handleStartDeletion = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setDeleteInProgress(true);
  };

  return (
    <>
      <DeleteDocumentDialog
        documentId={document.id}
        isOpen={deleteInProgress}
        onClose={() => setDeleteInProgress(false)}
        data-testid="delete-document-dialog"
      />

      <InsightsCreatedAtCell
        row={row}
        classNames={{
          dropdownButton: cn(
            error ? "text-destructive" : "text-muted-foreground"
          ),
        }}
        data-testid="insights-created-at-cell"
      >
        <div
          className={"group-hover:flex hidden"}
          data-testid="hover-container"
        >
          <LoadingButton
            isLoading={deleteInProgress}
            onClick={handleStartDeletion}
            variant={"icon"}
            size={"xs"}
            className={
              "bg-accent rounded-[8px] border border-solid skip-close-upload"
            }
            data-testid="delete-button"
          >
            <TrashIcon
              className={"text-foreground h-4 w-4"}
              data-testid="trash-icon"
            />
          </LoadingButton>
        </div>
      </InsightsCreatedAtCell>
    </>
  );
};
