import { Dispatch, RefObject, SetStateAction } from "react";
import { InfiniteData, QueryClient } from "react-query";

import { deleteDocumentsBatch } from "@atlas-ui/services";
import { Document, DocumentStatus, PaginatedResponse } from "@atlas-ui/types";
import { EventData } from "@atlas-ui/utils";
import { LinkIcon } from "@heroicons/react/24/outline";

import {
  DEFAULT_KVPS_ORDER,
  DOCUMENT_TYPE_KVP_NAME,
  EFFECTIVE_DATE_KVP_NAME,
  IN_PROGRESS_QUERY_KEY,
} from "@/app/documents/[id]/lib/constants";
import { DOCUMENT_TYPE_LOCALSTORAGE_KEY } from "@/app/my-documents/lib/constants";
import {
  acceptsMessage,
  getMaxFilesMessage,
  getSizeExceededFiles,
  getUnsupportedFiles,
  InvalidFileReason,
  maxFileLengthMessage,
  SupportedMimeType,
  UploadError,
} from "@/lib/common/helpers/files-helper";

import dayjs from "dayjs";
import { isEmpty, sortBy, uniqBy } from "lodash";
import Link from "next/link";

import { MAX_DOCUMENTS_IN_PROGRESS } from "../hooks/use-my-documents";

export const parseSortingOption = (
  sortingOption: string
): [string, "asc" | "desc"] => {
  const [sorting, sortDirection] = sortingOption.split(":");
  return [sorting, (sortDirection ?? "desc") as "asc" | "desc"];
};

export const upsertDocumentInCache = ({
  queryClient,
  currentQueryKey,
  receivedDocument,
  currentPage,
}: {
  queryClient: QueryClient;
  currentQueryKey: string[];
  receivedDocument: Document;
  currentPage: number;
}) => {
  queryClient.setQueryData(
    currentQueryKey,
    (oldData?: InfiniteData<PaginatedResponse<Document>>) => {
      if (!oldData) {
        return oldData as unknown as InfiniteData<PaginatedResponse<Document>>;
      }

      const pageIndex = currentPage - 1;
      const page = oldData.pages[pageIndex];

      if (!page) {
        return oldData as unknown as InfiniteData<PaginatedResponse<Document>>;
      }

      let documentExists = false;
      const updatedResult = page.result.map((document) => {
        const isMatch = document.id === receivedDocument.id;

        if (isMatch) {
          documentExists = true;
        }
        return isMatch ? receivedDocument : document;
      });

      if (!documentExists) {
        updatedResult.unshift(receivedDocument);
      }

      return {
        ...oldData,
        pages: oldData.pages.map((page, index) => {
          if (index === pageIndex) {
            return {
              ...page,
              result: updatedResult,
            };
          }

          return page;
        }),
      };
    }
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFromLocalStorage = (key: string, defaultValue: any) => {
  try {
    const value = JSON.parse(localStorage.getItem(key) as string);

    // Patch of older versions saving "all" as document type
    if (
      key === DOCUMENT_TYPE_LOCALSTORAGE_KEY &&
      value.toLowerCase() === "all"
    ) {
      return defaultValue;
    }

    if (!value) return defaultValue;

    return value;
  } catch {
    return defaultValue;
  }
};

export const saveToLocalStorage = (key: string, value: unknown) => {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.removeItem(key);
  }
};

export const removeFailedDocuments = async (result: Document[]) => {
  const failedDocuments = result.filter(
    (document) => document.status === DocumentStatus.preprocessFailed
  );

  if (!isEmpty(failedDocuments)) {
    await deleteDocumentsBatch(failedDocuments.map(({ id }) => id));
  }
};

export const createDocumentInCache = ({
  queryClient,
  currentQueryKey,
  data,
}: {
  queryClient: QueryClient;
  currentQueryKey: string[];
  data: EventData;
}) => {
  queryClient.setQueryData(
    currentQueryKey,
    (oldData?: InfiniteData<PaginatedResponse<Document>>) => {
      if (!oldData) {
        return oldData as unknown as InfiniteData<PaginatedResponse<Document>>;
      }

      const page = oldData.pages[0];

      if (!page) {
        return oldData as unknown as InfiniteData<PaginatedResponse<Document>>;
      }

      const updatedResult = [data as unknown as Document, ...page.result];

      return {
        ...oldData,
        pages: oldData.pages.map((page, index) => {
          if (index === 0) {
            return {
              ...page,
              result: updatedResult,
            };
          }

          return page;
        }),
      };
    }
  );
};

export const getInvalidFileReasonMessage = (reason: InvalidFileReason) => {
  if (reason === InvalidFileReason.UNSUPPORTED) {
    return "Unsupported file type";
  } else if (reason === InvalidFileReason.SIZE_EXCEEDED) {
    return "File size exceeded";
  }

  return "Unknown error";
};

export const deleteDocumentInCache = (
  queryClient: QueryClient,
  currentQueryKey: string[],
  data: EventData
) => {
  queryClient.setQueryData(
    currentQueryKey,
    (oldData?: InfiniteData<PaginatedResponse<Document>>) => {
      return {
        ...oldData,
        pages: oldData?.pages.map((page) => {
          return {
            ...page,
            result: page.result.filter((document) => document.id !== data.id),
          };
        }),
      } as InfiniteData<PaginatedResponse<Document>>;
    }
  );

  queryClient.setQueryData(IN_PROGRESS_QUERY_KEY, (oldData?: Document[]) => {
    return oldData?.filter((document) => document.id !== data.id) as Document[];
  });
};

export const compromisedErrorCommonKeys = {
  title: "Document compromised",
  acceptLabel: "Discard",
  acceptVariant: "secondary",
};

export const getDocumentCompromisedMessage = (
  modalType: string,
  document: Document
) => (
  <div className={"flex flex-col gap-4"}>
    <div>
      <span className={"text-destructive"}>Warning: </span> This document is
      being used inside one or many Insights. Please delete or modify insights
      in order to {modalType} this document.
    </div>
    <div className={"flex flex-col gap-4"}>
      {document?.insights?.map((insight) => (
        <div key={insight.id} className={"flex gap-2 items-center"}>
          <LinkIcon className={"w-4 h-4 text-muted-foreground"} />
          <Link
            href={`/insights/summary/${insight.id}`}
            target={"_blank"}
            className={"text-primary"}
          >
            {insight.name ?? "Untitled Insight"}
          </Link>
        </div>
      ))}
    </div>
  </div>
);

export const getDocumentCompromisedOnDeletionError = (document: Document) => {
  return {
    ...compromisedErrorCommonKeys,
    message: getDocumentCompromisedMessage("delete", document),
  };
};

export const validateUpload = ({
  files,
  maxFiles,
  accepts,
  maxFileLength,
  availableDocs,
  inProgressDocs,
}: {
  files: File[];
  maxFiles: number;
  accepts?: string[];
  maxFileLength?: number;
  availableDocs?: number;
  inProgressDocs?: number;
}) => {
  const errors: UploadError[] = [];

  if (files.length > maxFiles) {
    errors.push({
      title: `Maximum files per upload is ${maxFiles}.`,
      message: "Please select less documents and try again.",
    });
  } else if (availableDocs && files.length > availableDocs) {
    let title = `You only have ${availableDocs} documents left.`;
    let message = "Please select less documents and try again.";

    if (availableDocs < 0) {
      title = "You have exceeded your document limit.";
      message = "";
    }

    errors.push({
      title,
      message,
    });
  } else if (
    inProgressDocs &&
    inProgressDocs + files.length > MAX_DOCUMENTS_IN_PROGRESS
  ) {
    errors.push({
      title: "You have reached the maximum number of documents in progress.",
      message: `Please wait for the current documents to finish processing, or try uploading a max of ${
        MAX_DOCUMENTS_IN_PROGRESS - inProgressDocs
      } documents.`,
    });
  }

  const unsupportedFiles = getUnsupportedFiles(
    files,
    accepts ?? ["application/pdf"]
  );
  const sizeExceededFiles = getSizeExceededFiles(files, maxFileLength);

  const shouldContinue =
    files.length <= maxFiles || sizeExceededFiles.length === files.length;

  if (sizeExceededFiles.length === files.length) {
    errors.push({
      title: "File size exceeded",
      message: "Please select files with a size less than 50MB and try again.",
    });
  }

  const results = {
    files: uniqBy(
      [
        ...unsupportedFiles.map((file) => ({
          file,
          reason: InvalidFileReason.UNSUPPORTED,
        })),
        ...sizeExceededFiles.map((file) => ({
          file,
          reason: InvalidFileReason.SIZE_EXCEEDED,
        })),
      ],
      (invalidFile) => invalidFile.file.name
    ),
    errors,
  };

  return {
    errors: results.errors,
    invalidFiles: results.files,
    shouldContinue: shouldContinue,
  };
};

export const handleErrors = (
  errors: UploadError[],
  setErrors: Dispatch<SetStateAction<UploadError[]>>
): boolean => {
  if (errors.length) {
    setErrors(errors);
    return true;
  }
  return false;
};

export const handleEmptyFiles = (
  files: File[],
  inputRef: RefObject<HTMLInputElement>
): boolean => !inputRef.current || isEmpty(files);

export const getUploadRequirements = (
  maxFiles: number,
  accepts?: SupportedMimeType[],
  maxFileLength?: number
): string => {
  const requirements = [
    getMaxFilesMessage(maxFiles),
    maxFileLengthMessage(maxFileLength),
    acceptsMessage(accepts),
  ].filter(Boolean);

  return requirements.length ? `(${requirements.join("; ")})` : "";
};

export const getDocumentProcessingError = (document: Document) =>
  document._isInvalid || document.status === DocumentStatus.preprocessFailed
    ? document._invalidReason ?? "Error processing document"
    : undefined;

export const isEqualIgnoreCase = (a: string, b: string) => {
  return a.toLowerCase() === b.toLowerCase();
};

export const getSortedKvps = (document: Document) => {
  return sortBy(
    sortBy(
      document?.kvps?.filter((kvp) => {
        return (
          !isEqualIgnoreCase(kvp.name, DOCUMENT_TYPE_KVP_NAME) &&
          !isEqualIgnoreCase(kvp.name, EFFECTIVE_DATE_KVP_NAME)
        );
      }) ?? [],
      (kvp) => {
        return kvp.name.toUpperCase();
      }
    ),
    (kvp) => {
      const index = DEFAULT_KVPS_ORDER.indexOf(kvp.name.toUpperCase());
      return index !== -1 ? index : Number.MAX_SAFE_INTEGER;
    }
  );
};

export const getTerminationDate = (document: Document) => {
  return dayjs(document.kvps?.[0]?.value ?? "");
};
