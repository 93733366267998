import { useContext, useMemo } from "react";

import { LimitType } from "@atlas-ui/types";
import { AuthenticationContext } from "@atlas-ui/utils";

export const useCalculatedLimits = (type: LimitType, used?: number) => {
  const { getLimit } = useContext(AuthenticationContext);

  const { limit, available: originalAvailable } = useMemo(() => {
    return getLimit(type);
  }, [getLimit, type]);

  const available = used ? limit - used : originalAvailable;

  const canProceed = (available ?? 0) > 0;

  return { limit, available, canProceed };
};
