import { KvpCode } from "@atlas-ui/types";

import { EasingDefinition } from "framer-motion";
import * as Yup from "yup";

export const DISABLED_DELETION_KVP_CODES = [
  KvpCode.EffectiveDate,
  KvpCode.SOWEffectiveDate,
  KvpCode.Type,
  KvpCode.Parties,
];

export const DISABLED_EDITION_KVP_CODES = [KvpCode.Type];

export const DEFAULT_KVP_KEYS = ["TYPE", "PARTIES", "EFFECTIVE_DATE"];
export const DOCUMENT_TYPE_KVP_NAME = "TYPE";
export const PARTIES_KVP_NAME = "PARTIES";
export const EFFECTIVE_DATE_KVP_NAME = "EFFECTIVE DATE";
export const DEFAULT_KVPS_ORDER = [
  DOCUMENT_TYPE_KVP_NAME,
  PARTIES_KVP_NAME,
  EFFECTIVE_DATE_KVP_NAME,
];

export const DOCUMENT_SEARCH_ROW_HEIGHT = 26;

export enum KeyValuePairOperation {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

export interface KeyValuePairOperationOptions {
  isFavorite?: boolean;
  isDeleting?: boolean;
  trackUpdateEvent?: boolean;
}

export const keyValuePairValidationInfo = Yup.object({
  name: Yup.string().required("The label cannot be empty"),
  value: Yup.string().required("The value cannot be empty"),
  section: Yup.string().required("The section cannot be empty"),
});

export const needsAttentionValidationSchema = Yup.object().shape({
  autoRenewal: Yup.boolean().nullable().required("Required"),
  autoRenewalClause: Yup.string().when("autoRenewal", {
    is: (value: boolean) => value,
    then: (schema) => schema.required("Required"),
  }),
  cancellationNotice: Yup.string().when("autoRenewal", {
    is: (value: boolean) => value,
    then: (schema) =>
      schema
        .matches(/^\d+ (Days|Months|Years)$/, "Must be in format 'number unit'")
        .required("Required"),
  }),
});

export const IN_PROGRESS_QUERY_KEY = ["inProgressDocuments"];

export const collapseDefaultProps = {
  ease: "easeInOut" as EasingDefinition,
  duration: 0.4,
};
