import { useQuery } from "react-query";

import { getDocumentTags } from "@atlas-ui/services";
import { DocumentTag } from "@atlas-ui/types";

export const useTags = (
  options: {
    documentTypeId?: string | null;
    insights?: boolean;
    enabled?: boolean;
  } = {
    enabled: true,
  }
) => {
  const { documentTypeId, insights } = options;
  const { data: tags, isLoading: isLoadingTags } = useQuery<DocumentTag[]>({
    enabled: options.enabled,
    queryKey: ["tags", documentTypeId, insights],
    queryFn: () => {
      return getDocumentTags({
        documentTypeId: documentTypeId,
        insights,
      });
    },
  });

  return {
    tags: tags ?? [],
    isLoadingTags,
  };
};
