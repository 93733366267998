import { useQuery, useQueryClient } from "react-query";

import { getDocumentsInProgress } from "@atlas-ui/services";
import { Document, LimitType } from "@atlas-ui/types";

import { IN_PROGRESS_QUERY_KEY } from "@/app/documents/[id]/lib/constants";
import { MAX_DOCUMENTS_IN_PROGRESS } from "@/app/my-documents/hooks/use-my-documents";
import { useCalculatedLimits } from "@/lib/common/hooks/use-calculated-limits";

import { uniqBy } from "lodash";

export const useInProgressDocuments = () => {
  const queryClient = useQueryClient();
  const { data } = useQuery<Document[], Error, Document[]>({
    queryKey: IN_PROGRESS_QUERY_KEY,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    queryFn: async () => {
      const previousDocuments = queryClient.getQueryData(
        IN_PROGRESS_QUERY_KEY
      ) as Document[];
      const failedDocuments: Document[] =
        previousDocuments?.filter((doc) => {
          return doc._invalidReason;
        }) ?? [];

      const newDocumentsInProgress = await getDocumentsInProgress();

      return uniqBy([...failedDocuments, ...newDocumentsInProgress], "id");
    },
    select: (docs: Document[]) =>
      docs.filter((doc) => doc.name || doc._invalidReason),
  });

  const { canProceed } = useCalculatedLimits(LimitType.DOCUMENTS_UPLOAD);

  const documents = data ?? [];

  const validDocuments = documents.filter(
    (doc) => !doc._invalidReason && !doc.status.includes("failed")
  );

  const canUploadMoreDocuments =
    validDocuments.length < MAX_DOCUMENTS_IN_PROGRESS && canProceed;

  return {
    documents,
    validDocuments,
    canUploadMoreDocuments,
  };
};
