// FILE: LoadingButton.tsx
import React, { ReactNode } from "react";

import {
  Button,
  ButtonProps,
  Spinner,
  SpinnerProps,
} from "@atlas-ui/components";

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  loadingText?: ReactNode;
  children: React.ReactNode;
  spinnerProps?: SpinnerProps;
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  isLoading,
  onClick,
  className,
  children,
  loadingText,
  spinnerProps,
  ...rest
}) => {
  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={isLoading}
      data-isloading={isLoading}
      {...rest}
    >
      {isLoading ? (
        <div className="flex items-center gap-2">
          <Spinner
            size={4}
            className="w-4 h-4"
            containerClass="w-4 h-4"
            {...spinnerProps}
          />
          {loadingText}
        </div>
      ) : (
        children
      )}
    </Button>
  );
};
