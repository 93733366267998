import { useQuery } from "react-query";

import { getAllParties } from "@atlas-ui/services";

export const useParties = (
  options: { documentTypeId?: string | null; insights?: boolean } = {}
) => {
  const { documentTypeId, insights } = options;
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["parties", documentTypeId, insights],
    refetchOnWindowFocus: false,
    queryFn: () =>
      getAllParties({
        documentTypeId: documentTypeId!,
        insights,
      }),
  });

  return {
    parties: data,
    isLoadingParties: isLoading,
    refetchParties: refetch,
  };
};
