import { Document, KvpCode } from "@atlas-ui/types";
import { CellContext, ColumnDef } from "@tanstack/react-table";

import { MyDocumentsCreatedAtCell } from "../components/document-list/cells/my-documents-created-at-cell";
import { MyDocumentsEffectiveDateCell } from "../components/document-list/cells/my-documents-effective-date-cell";
import {
  MyDocumentsExpiredCell,
  MyDocumentsExpiredCellProps,
} from "../components/document-list/cells/my-documents-expired-cell";
import { MyDocumentsNameCell } from "../components/document-list/cells/my-documents-name-cell";
import { MyDocumentsTypeCell } from "../components/document-list/cells/my-documents-type-cell";
import { MyDocumentsNameHeader } from "../components/document-list/my-documents-name-header";
import { createSortOptions, getKvpValueByCodeAccessor } from "./sort-utils";

export const ITEMS_PER_PAGE = 50;
export const TAB_LOCALSTORAGE_KEY = "myContractsTab";
export const PARTIES_LOCALSTORAGE_KEY = "myContractsParties";
export const TAGS_LOCALSTORAGE_KEY = "myContractsTags";
export const DOCUMENT_TYPE_LOCALSTORAGE_KEY = "myContractsDocumentType";
export const SEARCH_BAR_LOCALSTORAGE_KEY = "myContractsSearchBar";
export const SORTING_OPTION_LOCALSTORAGE_KEY = "myContractsSortingOption";

// Common columns for both active and inactive document tables
const commonDocumentColumns: ColumnDef<Document>[] = [
  {
    accessorKey: "name",
    header: MyDocumentsNameHeader,
    cell: MyDocumentsNameCell,
    meta: {
      sortOptions: createSortOptions(
        "name",
        "By Document name Z-A",
        "By Document name A-Z",
        true
      ),
    },
  },
  {
    accessorKey: "type",
    header: "Document Type",
    cell: MyDocumentsTypeCell,
    size: 115,
    meta: {
      sortOptions: createSortOptions(
        "type",
        "By Document type Z-A",
        "By Document type A-Z",
        true
      ),
    },
  },
  {
    header: "Effective Date",
    accessorFn: getKvpValueByCodeAccessor(KvpCode.EffectiveDate),
    cell: MyDocumentsEffectiveDateCell,
    size: 108,
    meta: {
      sortOptions: createSortOptions(
        "effectiveDate",
        "Newest first",
        "Oldest first"
      ),
    },
  },
  {
    header: "Uploaded",
    accessorKey: "createdAt",
    cell: MyDocumentsCreatedAtCell,
    size: 130,
    meta: {
      sortOptions: createSortOptions(
        "createdAt",
        "Newest first",
        "Oldest first"
      ),
    },
  },
] as ColumnDef<Document>[];

// Active document columns
export const activeDocumentColumns: ColumnDef<Document>[] = [
  ...commonDocumentColumns,
];

// Inactive document columns with "Expired" column inserted
export const inactiveDocumentColumns: ColumnDef<Document>[] = [
  ...commonDocumentColumns.slice(0, 3),
  {
    header: "Expired",
    size: 80,
    accessorKey: "expirationDate",
    cell: (props: CellContext<Document, unknown>) => (
      <MyDocumentsExpiredCell {...(props as MyDocumentsExpiredCellProps)} />
    ),
    meta: {
      sortOptions: createSortOptions(
        "expirationDate",
        "Newest first",
        "Oldest first"
      ),
    },
  },
  ...commonDocumentColumns.slice(3),
];
