import React, { FC, KeyboardEventHandler, useContext } from "react";

import { AiIcon } from "@atlas-ui/components";
import { Document, DocumentStatus } from "@atlas-ui/types";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Row } from "@tanstack/react-table";

import { MyDocumentsContext } from "@/app/my-documents/lib";
import { NameCellTags } from "@/components/common/name-cell-tags";

import { truncate } from "lodash";
import { useRouter } from "next/navigation";

interface MyDocumentsNameCellProps {
  row: Row<Document>;
}

export const MyDocumentsNameCell: FC<MyDocumentsNameCellProps> = ({ row }) => {
  const { setSelectedTags } = useContext(MyDocumentsContext);

  const router = useRouter();
  const document = row.original;
  const openDocument = () => router.push(`/documents/${document.id}`);

  const handleDownloadOnKeyDown: KeyboardEventHandler<HTMLButtonElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      openDocument();
    }
  };

  const documentIsProcessing = [
    DocumentStatus.preprocessInProgress,
    DocumentStatus.docxToPdfInProgress,
    DocumentStatus.kvpInProgress,
    DocumentStatus.extraKvpInProgress,
  ].includes(row.original.status);

  return (
    <div
      className="w-full flex flex-col gap-2"
      data-testid="name-cell-container"
    >
      <button
        className={"w-full flex items-center gap-2 text-left"}
        onClick={openDocument}
        data-testid="document-button"
        onKeyDown={handleDownloadOnKeyDown}
      >
        <div className="whitespace-pre" data-testid="document-name-container">
          <span
            data-testid="document-name"
            className={
              "line-clamp-1 text-left text-foreground text-sm font-normal hover:text-primary hover:underline"
            }
          >
            {truncate(document.name, { length: 50, omission: "..." })}
          </span>
        </div>
        {document.isNewUpload && !documentIsProcessing && (
          <div
            className="text-primary text-[10px] flex gap-1 min-w-[80px]"
            data-testid="new-upload"
          >
            <AiIcon />
            <span>New Upload</span>
          </div>
        )}
        {documentIsProcessing && (
          <div
            className="text-muted-foreground text-[10px] flex gap-1 min-w-[80px]"
            data-testid="processing"
          >
            <ArrowPathIcon className="w-[14px] h-[14px] text-muted-foreground" />
            <span>Processing...</span>
          </div>
        )}
      </button>
      {document.tags?.length > 0 && (
        <NameCellTags
          document={document}
          onTagClicked={setSelectedTags}
          data-testid="name-cell-tags"
        />
      )}
    </div>
  );
};
