/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useContext, useState } from "react";
import { useQueryClient } from "react-query";

import { ConfirmationDialog, ErrorContext } from "@atlas-ui/components";
import { deleteDocument } from "@atlas-ui/services";

import { MyDocumentsContext } from "@/app/my-documents/lib";
import { getDocumentCompromisedOnDeletionError } from "@/app/my-documents/lib/utils";

import mixpanel from "mixpanel-browser";

interface DeleteDocumentDialogProps {
  documentId?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onDelete?: () => void;
}

interface InsightErrorPayload {
  insightName: string;
  insightId: string;
}

export const DeleteDocumentDialog: FC<DeleteDocumentDialogProps> = ({
  documentId,
  isOpen,
  onClose,
  onDelete,
}) => {
  const queryClient = useQueryClient();
  const { setError } = useContext(ErrorContext);
  const { documents } = useContext(MyDocumentsContext);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleSuccessfulDeletion = () => {
    mixpanel.track("action-success-destroy-contract");
  };

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries(["dashboard-tab-count"]);
    await queryClient.invalidateQueries(["document-types-count"]);
    await queryClient.invalidateQueries({
      queryKey: ["documents"],
      exact: false,
    });
  };

  const handleError = (error: any) => {
    if (error.errorCode === "DOCUMENT_LOCKED_BY_INSIGHT") {
      const document = documents.find((doc) => doc.id === documentId);

      if (!document) {
        console.error(
          "Error handling DeleteDocumentDialog deletion: Document not found",
          { documentId, errorMessage: error.response?.data.data.message }
        );
        return;
      }

      setError(
        getDocumentCompromisedOnDeletionError({
          ...document,
          insights: [
            ...error.insights.map((insight: InsightErrorPayload) => ({
              name: insight.insightName,
              id: insight.insightId,
            })),
          ],
        })
      );
    } else {
      setError({
        title: "Error deleting document",
        message: "Please try again later",
      });
    }
  };

  const onDeleteDocument = async () => {
    if (!documentId) return;

    try {
      setIsDeleting(true);
      await deleteDocument(documentId);
      handleSuccessfulDeletion();
      await invalidateQueries();
      onDelete?.();
    } catch (error) {
      handleError(error);
    } finally {
      setIsDeleting(false);
      onClose?.();
    }
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title="Delete Document?"
      message="This action cannot be undone. This will permanently delete this document and it's data from our servers."
      onConfirm={onDeleteDocument}
      onCancel={onClose}
      onClose={onClose}
      isLoading={isDeleting}
      testId="delete-document"
      options={{
        confirmLabel: "Confirm delete",
      }}
    />
  );
};
