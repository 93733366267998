import { HTMLAttributes, ReactNode } from "react";

import { Document, KvpCode } from "@atlas-ui/types";
import { Row } from "@tanstack/react-table";

import { getKvpValueByCodeAccessor } from "@/app/my-documents/lib/sort-utils";

import dayjs from "dayjs";

interface InsightsEffectiveDateCellProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  row: Row<Document>;
  children?: (effectiveDate: string) => ReactNode;
}

export const InsightsEffectiveDateCell = ({
  row,
  children,
  ...rest
}: InsightsEffectiveDateCellProps) => {
  const date = getKvpValueByCodeAccessor(KvpCode.EffectiveDate)(row.original) as string;
  const getFormattedDate = () => {
    const dayjsDate = dayjs(date);

    if (!dayjsDate.isValid()) {
      return "N/A";
    }

    return dayjsDate.format("MMM DD, YYYY");
  };

  return (
    <div className="h-full flex items-start pt-[6px]" {...rest}>
      {children !== undefined ? (
        children(getFormattedDate())
      ) : (
        <span className={"line-clamp-1"} data-testid="effective-date">
          {getFormattedDate()}
        </span>
      )}
    </div>
  );
};
